<template>
  <Modal :close="closeModal" :modal-active="showModal">
    <template v-slot:default="{ close }">
      <div class="container text-center mt-3 col-12">
        <Icon name="warning" class="warning-icon mt-4" />
        <div class="row"></div>
        <div class="mt-4 text-center">
          <p v-if="message" class="text">{{ message }}</p>
          <slot />
        </div>
        <div class="container mt-4">
          <div class="d-flex justify-content-around mt-4 mb-3 row">
            <b-button type="button" class="btn border rounded button-close col-5" @click="close">
              Fechar
            </b-button>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>


<script>
import Modal from "@/components/Modal/WarningModal";

export default {
  name: "ModalCancel",
  components: {
    Modal
  },
  props: {
    message: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showModal: true
    }
  },
  methods: {
    closeModal () {
      this.showModal = false;
    }
  }
};
</script>


<style lang="scss" scoped>
.warning-text{
  color: crimson;
  font-size: 18px;
}
.warning-text-2{
  color: crimson;
  font-size: 14px;
}
.button-close{
  color: #FFF;
  font-size: small;
  background-color: crimson;
}
.warning-icon{
  width: 45px;
}
.text{
  color: #141414;
  font-size: 16px;
  font-weight: 400;
}
</style>
